import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Seo from '../components/SeoComponent';
import PhoneComponent from '../components/PhoneComponent';
import { Container, Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MiniAd from '../templates/MiniAdTemplate';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import sanitizeHtml from 'sanitize-html';

const useTableStyles = makeStyles({
    root: {
        border: `1px solid #d5d6da`,
        borderCollapse: `collapse`,
        textAlign: `left`,
        tableLayout: `fixed`,
        padding: `20px 0 44px 0`,
        '& > tbody > tr:last-child > td': {
            borderBottomWidth: '0',
        },
    },
    tableCell: {
        borderLeft: `1px solid #d5d6da`,
        borderBottom: '1px solid #d5d6da',
        borderCollapse: `collapse`,
        textAlign: `left`,
        tableLayout: `fixed`,
        '& > ul': {
            margin: '0',
            paddingLeft: '0',
            listStyleType: 'none',
        },
        '& > ul > li:not(:last-child)': {
            paddingBottom: '5px',
        },
    },
});

// const useStyles = makeStyles({
//     root: {
//         display: 'flex',
//         flexDirection: 'column',
//         '& > *:not(:last-child)': {
//             marginBottom: 30,
//         },
//         padding: `20px 0 44px 0`,
//     },
// });

const renderingOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>,
    },
};
function SpecsTemplate(props) {
    // const post = get(props, 'data.contentfulDeviceSpecs');
    const adData = get(props, 'data.DeviceData');
    const postBrandName = adData && adData.brandName ? sanitizeHtml(adData.brandName) : '';

    const postTitle = adData && adData.title ? sanitizeHtml(adData.title) : '';
    const postPrice = adData && adData.adBlockDynamicPrice ? sanitizeHtml(adData.adBlockDynamicPrice) : '0.0';
    const postPromoText = adData && adData.adBlockPromoText ? adData.adBlockPromoText : '';
    const postDescription = adData && adData.adBlockDescription ? adData.adBlockDescription : '';

    const specs = get(props, 'data.specsData');
    const metaTags = get(props, 'data.metaTags');

    const networkSectionTitle = specs && specs.networkSectionTitle ? specs.networkSectionTitle : '';
    const networkTechnology = specs && specs.networkTechnology ? specs.networkTechnology : '';
    const networkSpeed = specs && specs.networkSpeed ? specs.networkSpeed : '';
    const launchSectionTitle = specs && specs.launchSectionTitle ? specs.launchSectionTitle : '';
    const launchAnnounced = specs && specs.launchAnnounced ? specs.launchAnnounced : '';
    const launchStatus = specs && specs.launchStatus ? specs.launchStatus : '';
    const bodySectionTitle = specs && specs.bodySectionTitle ? specs.bodySectionTitle : '';
    const bodyDimensions = specs && specs.bodyDimensions ? specs.bodyDimensions : '';
    const bodyWeight = specs && specs.bodyWeight ? specs.bodyWeight : '';
    const bodyBuild = specs && specs.bodyBuild ? specs.bodyBuild : '';
    const bodySim = specs && specs.bodySim ? specs.bodySim : '';
    const displaySectionTitle = specs && specs.displaySectionTitle ? specs.displaySectionTitle : '';
    const displayType = specs && specs.displayType ? specs.displayType : '';
    const displaySize = specs && specs.displaySize ? specs.displaySize : '';
    const displayResolution = specs && specs.displayResolution ? specs.displayResolution : '';
    const displayProtection = specs && specs.displayProtection ? specs.displayProtection : '';
    const platformSectionTitle = specs && specs.platformSectionTitle ? specs.platformSectionTitle : '';
    const platformOs = specs && specs.platformOs ? specs.platformOs : '';
    const platformCpu = specs && specs.platformCpu ? specs.platformCpu : '';
    const platformGpu = specs && specs.platformGpu ? specs.platformGpu : '';
    const platformChipset = specs && specs.platformChipset ? specs.platformChipset : '';
    const memorySectionTitle = specs && specs.memorySectionTitle ? specs.memorySectionTitle : '';
    const memoryCardSlot = specs && specs.memoryCardSlot ? specs.memoryCardSlot : '';

    const memoryInternal = specs && specs.memoryInternal ? specs.memoryInternal : '';
    const mainCameraSectionTitle = specs && specs.mainCameraSectionTitle ? specs.mainCameraSectionTitle : '';
    const mainCameraQuad = specs && specs.mainCameraQuad ? specs.mainCameraQuad : '';
    const mainCameraTriple = specs && specs.mainCameraTriple ? specs.mainCameraTriple : '';
    const mainCameraDual = specs && specs.mainCameraDual ? specs.mainCameraDual : '';
    const mainCameraSingle = specs && specs.mainCameraSingle ? specs.mainCameraSingle : '';
    const mainCameraFeatures = specs && specs.mainCameraFeatures ? specs.mainCameraFeatures : '';
    const mainCameraVideo = specs && specs.mainCameraVideo ? specs.mainCameraVideo : '';
    const selfieCameraSectionTitle = specs && specs.selfieCameraSectionTitle ? specs.selfieCameraSectionTitle : '';

    const selfieCameraDual = specs && specs.selfieCameraDual ? specs.selfieCameraDual : '';
    const selfieCameraSingle = specs && specs.selfieCameraSingle ? specs.selfieCameraSingle : '';
    const selfieCameraVideo = specs && specs.selfieCameraVideo ? specs.selfieCameraVideo : '';
    const soundSectionTitle = specs && specs.soundSectionTitle ? specs.soundSectionTitle : '';
    const sound35mmJack = specs && specs.sound35mmJack ? specs.sound35mmJack : '';
    const soundLoudspeaker = specs && specs.soundLoudspeaker ? specs.soundLoudspeaker : '';
    const featuresSectionTitle = specs && specs.featuresSectionTitle ? specs.featuresSectionTitle : '';
    const featuresSensors = specs && specs.featuresSensors ? specs.featuresSensors : '';
    const batterySectionTitle = specs && specs.batterySectionTitle ? specs.batterySectionTitle : '';
    const batteryType = specs && specs.batteryType ? specs.batteryType : '';
    const batteryStandBy = specs && specs.batteryStandBy ? specs.batteryStandBy : '';
    const batteryCharging = specs && specs.batteryCharging ? specs.batteryCharging : '';
    const batteryMusicPlay = specs && specs.batteryMusicPlay ? specs.batteryMusicPlay : '';

    const metaTitle = postTitle + ' | Specifications | Asurion Mobile+';
    const metaDescription = metaTags && metaTags.description ? metaTags.description : '';
    const specImage = metaTags && metaTags.ogImage ? metaTags.ogImage.file.url : '';

    // const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
    const domainUrl = '';
    const canonicalUrl =
        props && props.pageContext && props.pageContext.canonicalPath
            ? domainUrl + props.pageContext.canonicalPath
            : domainUrl + props.path;

    console.log('canonical', canonicalUrl);

    // const classes = useStyles();
    const classNames = useTableStyles();

    return (
        <PhoneComponent
            postTitle={postTitle}
            brandName={postBrandName}
            adBlockDynamicPrice={postPrice}
            adBlockPromoText={postPromoText}
            adBlockDescription={postDescription}
            activeTab={'specs'}
            canonicalUrl={canonicalUrl}
            subcomponent={
                <>
                    <Seo
                        title={metaTitle}
                        description={metaDescription}
                        image={specImage}
                        ogImage={specImage}
                        canonical={canonicalUrl}
                    />
                    <Container maxWidth="ssm" fixed className="ltArticleContainer">
                        <Typography style={{ fontWeight: '600', fontSize: '16px', lineHeight: '19px' }} align="left">
                            {networkSectionTitle}
                        </Typography>
                        <Table className={classNames.root} size="small" sx={{ mt: 1, mb: 3 }}>
                            <TableBody>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Technology</TableHead>
                                    {networkTechnology && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {networkTechnology}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Speed</TableHead>
                                    {networkSpeed && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {networkSpeed}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography style={{ fontWeight: '600', fontSize: '16px', lineHeight: '19px' }} align="left">
                            {launchSectionTitle}
                        </Typography>
                        <Table className={classNames.root} size="small" sx={{ mt: 1, mb: 3 }}>
                            <TableBody>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Announced</TableHead>
                                    {launchAnnounced && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {launchAnnounced}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Status</TableHead>
                                    {launchStatus && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {launchStatus}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography style={{ fontWeight: '600', fontSize: '16px', lineHeight: '19px' }} align="left">
                            {bodySectionTitle}
                        </Typography>
                        <Table className={classNames.root} size="small" sx={{ mt: 1, mb: 3 }}>
                            <TableBody>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Dimensions</TableHead>
                                    {bodyDimensions && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {bodyDimensions}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Weight</TableHead>
                                    {bodyWeight && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {bodyWeight}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Build</TableHead>
                                    {bodyBuild && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {bodyBuild}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>SIM</TableHead>
                                    {bodySim && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {renderRichText(bodySim, renderingOptions)}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography style={{ fontWeight: '600', fontSize: '16px', lineHeight: '19px' }} align="left">
                            {displaySectionTitle}
                        </Typography>
                        <Table className={classNames.root} size="small" sx={{ mt: 1, mb: 3 }}>
                            <TableBody>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Type</TableHead>
                                    {displayType && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {displayType}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Size</TableHead>
                                    {displaySize && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {displaySize}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Resolution</TableHead>
                                    {displayResolution && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {displayResolution}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Protection</TableHead>
                                    {displayProtection && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {renderRichText(displayProtection, renderingOptions)}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>

                        <MiniAd sx={{ width: 1 }}>
                            <Typography sx={{ fontSize: '16px', lineHeight: '16px' }}>
                                44%of Australians don’t use a screen protector on their phone.
                            </Typography>
                        </MiniAd>

                        <Typography style={{ fontWeight: '600', fontSize: '16px', lineHeight: '19px' }} align="left">
                            {platformSectionTitle}
                        </Typography>
                        <Table className={classNames.root} size="small" sx={{ mt: 1, mb: 3 }}>
                            <TableBody>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}> OS</TableHead>
                                    {platformOs && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {platformOs}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}> Chipset</TableHead>
                                    {platformChipset && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {platformChipset}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>CPU</TableHead>
                                    {platformCpu && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {platformCpu}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}> GPU</TableHead>
                                    {platformGpu && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {platformGpu}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography style={{ fontWeight: '600', fontSize: '16px', lineHeight: '19px' }} align="left">
                            {memorySectionTitle}
                        </Typography>
                        <Table className={classNames.root} size="small" sx={{ mt: 1, mb: 3 }}>
                            <TableBody>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}> Card Slot</TableHead>
                                    {memoryCardSlot && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {memoryCardSlot}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Internal</TableHead>
                                    {memoryInternal && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {memoryInternal}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography style={{ fontWeight: '600', fontSize: '16px', lineHeight: '19px' }} align="left">
                            {mainCameraSectionTitle}
                        </Typography>
                        <Table className={classNames.root} size="small" sx={{ mt: 1, mb: 3 }}>
                            <TableBody>
                                {mainCameraQuad && (
                                    <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Quad</TableHead>
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {renderRichText(mainCameraQuad, renderingOptions)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {mainCameraTriple && (
                                    <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Triple</TableHead>
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {renderRichText(mainCameraTriple, renderingOptions)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {mainCameraDual && (
                                    <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Dual</TableHead>
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {renderRichText(mainCameraDual, renderingOptions)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {mainCameraSingle && (
                                    <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Single</TableHead>
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {mainCameraSingle}
                                        </TableCell>
                                    </TableRow>
                                )}
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}> Video</TableHead>
                                    {mainCameraVideo && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {mainCameraVideo}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Features</TableHead>
                                    {mainCameraFeatures && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {mainCameraFeatures}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography style={{ fontWeight: '600', fontSize: '16px', lineHeight: '19px' }} align="left">
                            {selfieCameraSectionTitle}
                        </Typography>
                        <Table className={classNames.root} size="small" sx={{ mt: 1, mb: 3 }}>
                            <TableBody>
                                {selfieCameraDual && (
                                    <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Dual</TableHead>
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {renderRichText(selfieCameraDual, renderingOptions)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {selfieCameraSingle && (
                                    <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Single</TableHead>

                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {selfieCameraSingle}
                                        </TableCell>
                                    </TableRow>
                                )}
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Video</TableHead>
                                    {selfieCameraVideo && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {selfieCameraVideo}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography style={{ fontWeight: '600', fontSize: '16px', lineHeight: '19px' }} align="left">
                            {soundSectionTitle}
                        </Typography>
                        <Table className={classNames.root} size="small" sx={{ mt: 1, mb: 3 }}>
                            <TableBody>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Loudspeaker</TableHead>
                                    {soundLoudspeaker && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {soundLoudspeaker}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>3.5mm Jack</TableHead>
                                    {sound35mmJack && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {sound35mmJack}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography style={{ fontWeight: '600', fontSize: '16px', lineHeight: '19px' }} align="left">
                            {featuresSectionTitle}
                        </Typography>
                        <Table className={classNames.root} size="small" sx={{ mt: 1, mb: 3 }}>
                            <TableBody>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>
                                        Features {`&`} Sensors
                                    </TableHead>
                                    {featuresSensors && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {renderRichText(featuresSensors, renderingOptions)}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography style={{ fontWeight: '600', fontSize: '16px', lineHeight: '19px' }} align="left">
                            {batterySectionTitle}
                        </Typography>
                        <Table className={classNames.root} size="small" sx={{ mt: 1, mb: 3 }}>
                            <TableBody>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Type</TableHead>
                                    {batteryType && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {batteryType}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Charging</TableHead>
                                    {batteryCharging && (
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {renderRichText(batteryCharging, renderingOptions)}
                                        </TableCell>
                                    )}
                                </TableRow>
                                {batteryStandBy && (
                                    <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>Stand-by</TableHead>
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {batteryStandBy}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {batteryMusicPlay && (
                                    <TableRow style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <TableHead style={{ width: '33.33%', paddingLeft: '10px' }}>
                                            Music Play
                                        </TableHead>
                                        <TableCell style={{ width: '66.67%' }} className={classNames.tableCell}>
                                            {batteryMusicPlay}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Container>
                </>
            }
        />
    );
}

export default SpecsTemplate;

// export default function SpecsMainTemplate(props) {
//     const classes = useTableStyles();

//     return <SpecsTemplate {...props} classes={classes} />;
// }

export const specsPageQuery = graphql`
    query specsQuery($title: String, $metaTagTitle: String) {
        DeviceData: contentfulDeviceDetailPage(title: { eq: $title }) {
            brand
            brandName
            title
            adBlockDynamicPrice
            adBlockPromoText {
                raw
            }
            adBlockDescription {
                raw
            }
        }
        specsData: contentfulDeviceSpecs(deviceTitle: { eq: $title }) {
            deviceTitle
            networkSectionTitle
            networkTechnology
            networkSpeed
            launchSectionTitle
            launchAnnounced
            launchStatus
            bodySectionTitle
            bodyDimensions
            bodyWeight
            bodyBuild
            bodySim {
                raw
            }
            displaySectionTitle
            displayType
            displaySize
            displayResolution
            displayProtection {
                raw
            }
            platformSectionTitle
            platformOs
            platformCpu
            platformGpu
            platformChipset
            memorySectionTitle
            memoryCardSlot
            memoryInternal
            mainCameraSectionTitle
            mainCameraQuad {
                raw
            }
            mainCameraDual {
                raw
            }
            mainCameraTriple {
                raw
            }
            mainCameraSingle
            mainCameraFeatures
            mainCameraVideo
            selfieCameraSectionTitle
            selfieCameraDual {
                raw
            }
            selfieCameraSingle
            selfieCameraVideo
            soundSectionTitle
            sound35mmJack
            soundLoudspeaker
            featuresSectionTitle
            featuresSensors {
                raw
            }
            batterySectionTitle
            batteryType
            batteryStandBy
            batteryCharging {
                raw
            }
            batteryMusicPlay
            comms {
                title
                wlan
                bluetooth
                gps
                nfc
                radio
                usb
            }
            misc {
                title
                colors
                models
                sarEu
            }
        }
        metaTags: contentfulMetaTags(title: { eq: $metaTagTitle }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                }
            }
        }
    }
`;
